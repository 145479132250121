// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-js": () => import("./../../../src/pages/ai.js" /* webpackChunkName: "component---src-pages-ai-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-board-js": () => import("./../../../src/pages/board.js" /* webpackChunkName: "component---src-pages-board-js" */),
  "component---src-pages-changes-js": () => import("./../../../src/pages/changes.js" /* webpackChunkName: "component---src-pages-changes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dataprivacy-js": () => import("./../../../src/pages/dataprivacy.js" /* webpackChunkName: "component---src-pages-dataprivacy-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-emailtemplate-js": () => import("./../../../src/pages/emailtemplate.js" /* webpackChunkName: "component---src-pages-emailtemplate-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-launch-js": () => import("./../../../src/pages/launch.js" /* webpackChunkName: "component---src-pages-launch-js" */),
  "component---src-pages-measure-what-matters-js": () => import("./../../../src/pages/measure-what-matters.js" /* webpackChunkName: "component---src-pages-measure-what-matters-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-pilot-js": () => import("./../../../src/pages/pilot.js" /* webpackChunkName: "component---src-pages-pilot-js" */),
  "component---src-pages-pilotterms-js": () => import("./../../../src/pages/pilotterms.js" /* webpackChunkName: "component---src-pages-pilotterms-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-purpose-js": () => import("./../../../src/pages/purpose.js" /* webpackChunkName: "component---src-pages-purpose-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-white-paper-js": () => import("./../../../src/pages/white-paper.js" /* webpackChunkName: "component---src-pages-white-paper-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-newsletter-post-js": () => import("./../../../src/templates/newsletter-post.js" /* webpackChunkName: "component---src-templates-newsletter-post-js" */)
}

